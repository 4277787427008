@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap');

.portfolio {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}


.portfolio-stuff {
  display: block;
  position: fixed;
  margin-left: 25%;
  padding: 2vh 1vw;
  align-self: center;

  scroll-behavior: smooth;
  overflow-x: none;
  overflow-y: scroll;
  overscroll-behavior: contain;

  text-align: center;
  color: grey;

  background: white;
  width: max(42%);
  height: 65vh;

  font-family: 'Didact Gothic', sans-serif;

}

h4 {
  margin-bottom: 1%;
}

.portfolio-items p{
  width: 50%;
  margin-left: 25%;
  margin-top: 1%;
}

.portfolio-items a{
  text-decoration: none;
  color: grey;
  font-size: 1rem;
  font-family: 'Didact Gothic', sans-serif;
}

.portfolio-items a:active{
  text-decoration: none;
  color: grey;
  font-size: 1rem;
  font-family: 'Didact Gothic', sans-serif;
}

.portfolio-items a:hover{
  text-decoration: underline double;
  color: pink;
  font-size: 1.25rem;
  font-family: 'Didact Gothic', sans-serif;
}

.about-me-stuff body {
  height: 50vh;
  padding: 2vh;
}

.portfolio-items img {
  width: 100%;

}