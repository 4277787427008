@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap');

.about-me {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

li {
  list-style-type: none;
  text-align: left;
  line-height: 95%;
  border: none;
  text-decoration: none;
}

h3 {
  padding-top: 2vh;
  padding-left: 10%;
}

.portrait {
  width: 130px;
  height: 130px;
  border-radius: 50%;
}

.intro {
  padding-top: 5vh;
}

.languages {
  display: flex;
    // want to create a grid of 3 columns that overflow into a new row if screen is too small
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  width: 100%;
}

.languages li {
  background-color: pink;
  color: black;
  text-align: center;
  border: 1px black solid;
  border-radius: 1em;
  padding: 0.5em;
  margin: 0.1em;
  width: 10vw;
  font-size: min(0.5vw + 1vmin);
}

.languages li:hover {
  background-color: black;
  color: whitesmoke;
  border: 1px, whitesmoke, solid;

}

.hireme {
  font-weight: bold;
}

.about-me-stuff {
  display: block;
  position: fixed;
  margin-left: 25%;
  padding: 2vh 1vw;
  align-self: center;

  scroll-behavior: smooth;
  overflow-x: none;
  overflow-y: scroll;
  overscroll-behavior: contain;

  text-align: center;
  color: grey;

  background: white;
  width: max(42%);
  height: 65vh;

  font-family: 'Didact Gothic', sans-serif;

}

.about-me-stuff body {
  height: 50vh;
  padding: 2vh;
}