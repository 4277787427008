@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap');

body{
    overflow-x: fixed;
    height: 80%;


    .navbar{
        display: grid;
        grid-template-columns: 1fr 3fr;
        align-items: center;
        overflow: contain;
        position: fixed;
        
        width: 100%;
        height: 10vh;
        padding-left: 1rem;
        padding-right: 1rem;
        margin-right: 2rem;
        margin-top: 3%;

        font-family: 'Didact Gothic', sans-serif;
        font-size: (1vh+2vh);
        color: black;
    }

    .navbar>h1 {
        margin-top:0.8em;
        margin-right: 10rem;
    }

    .navbar ul {
        list-style: none;
        overflow: fixed;
        display: grid;
        grid-template-columns: repeat( 4 , 1fr);
        justify-self: end;
        margin-top: 1%;
        transition: 1s;
        margin-right: 2rem;
        height: 10vh;
    }

    .navbar ul li a {
        overflow: contain;
        align-items: center;
        text-align: center ; 
        line-height: 5rem;
        margin-bottom: 1vh;
        transition: 0.8s;
        font-size: (max(1rem) +.5rem);
        text-decoration: none;
        color: black;
        display:block;
        width: 5rem;
        height: 5rem;
    }

    .navbar li a:hover {
        background-color: pink;
    }

    @media (max-width:700vw) {
        .navbar h1 {
            display: flex;
        }
    }
    
    h1 #nav-icon1{
        margin-top: 10px;
        margin-right: 7rem;
        width: 60px;
        height: 50px;

    }

    h1 {
        z-index: 1;
    }

    .navbar {
        grid-template-columns: 1fr;
        width: 100%;
    }

    .navbar >ul{
        grid-template-columns: 1fr;
        margin-right: -2rem;
        margin-bottom: 3rem;
        background-color: white;
        width: 35vw;
        padding: 1em;
        height: 95vh;
        margin-top: 2rem;
        position: fixed;
        align-items: center;
    }

    .navbar ul>li:nth-child(1){
        margin-top: 5rem;
    }

    .navbar ul>li{
        align-self: center;
        border-bottom: 1px solid gray;
        width: 103%;
        padding-bottom: .5vh;
        padding-left: 3.5vw;
        text-align: justify-self;
    }

    .navbar ul> li a:hover {
        background-color: pink;
        border-radius: 100%;
    }


    .navigation-close {
        top: 1em;
        right: -100%;
    }

    .navigation-open { 
        top: 1em;
        right: 0%;
    }

}