@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap');

.contact {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.contact-stuff {
  display: block;
  position: fixed;
  margin-left: 25%;
  padding: 2vh 1vw;
  align-self: center;

  scroll-behavior: smooth;
  overflow-x: none;
  overflow-y: scroll;
  overscroll-behavior: contain;

  text-align: center;
  color: grey;

  background: white;
  width: max(42%);
  height: 45vh;

  font-family: 'Didact Gothic', sans-serif;

}

.contact-stuff p {
  margin-top: 5%;
  width: 75%;
  margin-left: 15%;
}

.contact-stuff ul {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.contact-list-item img {
  width: 5vw;
  margin: 1vw;
}

.contact-list-link a{
  text-decoration: none;
  color: grey;
  font-size: 1rem;
  font-family: 'Didact Gothic', sans-serif;
}

.contact-list-link a:active{
  text-decoration: none;
  color: grey;
  font-size: 1rem;
  font-family: 'Didact Gothic', sans-serif;
}

.contact-list-link a:hover{
  text-decoration: underline double;
  color: pink;
  font-size: 1.25rem;
  font-family: 'Didact Gothic', sans-serif;
}

.contact-list-item a:hover{
  color:#94bbe9;
}

.contact-stuff body {
  height: 100%;
  overflow-y: contain;
}