@import url('https://fonts.googleapis.com/css2?family=Corben:wght@700&family=Didact+Gothic&display=swap');

.hero-main{
  display: grid;
  grid-template-columns: 60% 40%;
  height: 100vh;
  width: 30vw;
  margin-top: 6em;
  margin-left: 17vw;
  font-size: 1.25vw;
}

.heading-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

body{
  background-color: white;
}

li {
  list-style: none;
  text-align: left;
  text-decoration: none;
}

.name{
  font-size: 3em;
  font-weight: bold;
  margin-left: -1em;
  margin-bottom: -0.9em;
  margin-top: -2em;
  font-family: 'Didact Gothic', sans-serif;
}

.about{
  // margin-left: -4em;
  font-weight: 600;
  font-family: 'Corben', cursive;
  color: rgb(95, 94, 94);
  text-decoration: underline;
}

button{
  margin: 1em;
  padding: 1em;
  border-radius: 1em;
  border: 0.5px solid gray;
  font-family: 'Didact Gothic', sans-serif;
  font-weight: 600;
  background-color: white;
  transition: 1s;
}

button:nth-child(2){
  background-color: black;
  color: white;

}

button:hover{
  background-color: black;
  color: white;
}

button:hover{
  background-color: gray;
  color: white;
}

.buttonWrap{
  margin-left: -5em;
}

.cursor{
  font-size: 3rem;
}

@media (max-width:6vw) {
  .hero-main{
    grid-template-columns: repeat(auto-fill, minmax(2vw, 1vh));
    margin-top: 1rem;
  }

  .cursor{
    font-size: 1.5vw;
    z-index: center;
  }

  .box-object{
    z-index: -1;
  }

  .name{
    font-size: 1.5rem;
    margin-left: 0.25em;
    margin-bottom: -4em;
  }

  .about{
    font-size: 0.75rem;
    margin-left: 0.25em;
    margin-top: -1em;
  }
  .buttonWrap {
    margin-left: 1.5vw;
  }
  .hero-main{
    opacity: 1;
  }
}